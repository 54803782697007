import React, {FC} from 'react';
// style
import theme from "styles/theme"
import "AurionCR/styles/_main.scss"
import "styles/index.scss"
// store
import {Provider} from "react-redux";
import store from "AurionCR/store"
import storeConfig from "store/redux"
// layout
import Page404 from 'AurionCR/pages/Page404'
import Layout from 'pages/Layout'
// router
import routesConfig from 'configs/routes';
// notify
import {SnackbarProvider} from "notistack";
// l18n
import {
  AuthContextProvider,
  I18nContextProvider,
  LoadingOverlayProvider,
  Notifier,
  ResponsiveProvider,
  Routes,
  RTLThemeProvider
} from "AurionCR/components";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {AxiosInterceptors} from "./components/axios-interceptors";

declare global {
  interface Window {
    authCookieName_: string;
    authRefreshCookieName_: string;
    authDateCookieName_: string;
  }
}
/**
 * TODO: TypeScript not apply yet!...
 * TODO: TypeScript not apply yet!...
 * TODO: TypeScript not apply yet!...
 */

const App: FC = () => {
  window.authCookieName_ = 'linqbio_crm_auth_token';
  window.authRefreshCookieName_ = 'linqbio_crm_auth_refresh';
  window.authDateCookieName_ = 'linqbio_crm_auth_expires';
  return <ResponsiveProvider>
    <Provider store={store(storeConfig)}>
      <RTLThemeProvider theme={theme}>
        <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
          <Notifier/>
          <AxiosInterceptors>
            <AuthContextProvider>
              <DndProvider backend={HTML5Backend}>
                <I18nContextProvider apiLabelsFilter='labelSource.key == "crm"'>
                    <LoadingOverlayProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Routes
                          layout={Layout}
                          routes={routesConfig}
                          page404={Page404}
                        />
                      </MuiPickersUtilsProvider>
                    </LoadingOverlayProvider>
                </I18nContextProvider>
              </DndProvider>
            </AuthContextProvider>
          </AxiosInterceptors>
        </SnackbarProvider>
      </RTLThemeProvider>
    </Provider>
  </ResponsiveProvider>;
};

export default App
