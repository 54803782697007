export enum PERMISSIONS {
  EDIT_WEBSITES = 'isAllowToEditWebsites',
  EDIT_SETTINGS = 'isAllowToEditSetting',
  EDIT_GROUPS = 'isAllowToEditGroups',
  EDIT_LABELS = 'isAllowToEditLabels',
  EDIT_PROFILE = 'isAllowToEditProfile',
  EDIT_CRM_USERS = 'isAllowToEditCRMUsers',
  EDIT_PERMISSIONS = 'isAllowToEditPermissions',
  VIEW_LOG = 'isAllowToViewLog',
  VIEW_SENSITIVE = 'isAllowToViewSensitiveInformation',
  RESET_SENSITIVE = 'isAllowToResetSensitiveInformation',
}
