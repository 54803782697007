import React, {useCallback} from "react";
import {AxiosInterceptorsToken} from "./token";
import {AxiosInterceptorsRefreshToken} from "./refresh-token";
import {apiStatic, baseURL, getCookies, updateTokenAll} from "AurionCR/components";
import {AxiosInterceptorsSources} from "./sources";

const refresh = async (data: { token: string, refreshToken: string }) => {
  const response = await apiStatic.post('Accounts/RefreshToken', data)
  const {data: {token}} = response

  return fetch(`${baseURL}Accounts/GetCurrentAppUser`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText)
      }
      return response
    })
}

export const AxiosInterceptors: React.FC = ({children}) => {
  const onRefresh = useCallback(async () => {
    const token = getCookies(window.authCookieName_)
    const refreshToken = getCookies(window.authRefreshCookieName_)
    const expires = getCookies(window.authDateCookieName_) as string

    if (!token || !refreshToken) {
      throw new Error('token-not-exist')
    }

    const {data: {refreshToken: RefreshToken, token: Token}} = await refresh({token, refreshToken})

    return {token: Token, refreshToken: RefreshToken, expires}
  }, [])

  const onError = useCallback((e: Error) => {
    if (window.location.pathname !== '/login') {
      window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`
    }
  }, [])

  return (
    <AxiosInterceptorsSources>
      <AxiosInterceptorsToken>
        <AxiosInterceptorsRefreshToken onRefresh={onRefresh} onSuccess={updateTokenAll} onError={onError}>
          {children}
        </AxiosInterceptorsRefreshToken>
      </AxiosInterceptorsToken>
    </AxiosInterceptorsSources>
  )
}
