import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from 'AurionCR/store/modules/auth';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { api, requestError } from 'AurionCR/components/helpers';
import { updateTokenAll } from '../AurionCR/components';

function* errorHandler(error: any) {
  const errorMessage = requestError(error, error?.message);
  yield put(notifyRequestResult(errorMessage, 'error'));
  return errorMessage;
}

function* getUser() {
  yield put(actions.authStart());
  try {
    let response = yield call(api.get, 'Accounts/GetCurrentAppUser');
    yield put(
      actions.authSuccess({
        ...response.data,
        userPosition: response.data.userCrmProfilePermission,
      }),
    );
  } catch (error) {
    yield put(actions.checkLoggedFail());
    yield call(errorHandler, error);
    return error;
  }
}

function* authUser(action: any) {
  yield put(actions.authStart());
  try {
    const { cookie, ...data } = action.data;
    let response = yield call(api.post, 'Accounts/LoginWithCode', data);
    const {
      expires,
      jwt: { token, refreshToken },
    } = response.data;
    yield updateTokenAll({ token, refreshToken, expires });

    if (response.status === 200) {
      try {
        yield call(getUser);
      } catch (error) {
        const errorMessage = yield call(errorHandler, error);
        yield put(actions.authFail(errorMessage));
      }
    } else {
      const errorMessage = yield call(errorHandler, response);
      yield put(actions.authFail(errorMessage));
    }
  } catch (error) {
    const errorMessage = yield call(errorHandler, error);
    yield put(actions.authFail(errorMessage));
  }
}

// @ts-ignore
function* authCode(action: any) {
  yield put(actions.authStart());
  try {
    yield call(api.post, 'Accounts/GeneratePassword', action.data);
    yield put(actions.authCodeSuccess());
  } catch (error) {
    const errorMessage = yield call(errorHandler, error);
    yield put(actions.authFail(errorMessage));
  }
}

function* logout() {
  yield put(actions.authStart());
  try {
    yield call(api.post, 'Accounts/Logout');
    yield put(actions.logoutSucceed());
    yield updateTokenAll();
  } catch (error) {
    const errorMessage = requestError(error);
    yield put(notifyRequestResult(errorMessage, 'error'));
    yield put(actions.authFail(errorMessage));
  }
}

export default [
  takeLatest('AUTH_GET_USER', getUser),
  takeLatest('AUTH_USER', authUser),
  takeLatest('AUTH_CODE', authCode),
  takeLatest('AUTH_INITIATE_LOGOUT', logout),
];
