import { lazy } from 'react';
import { IRoute } from 'AurionCR/components/router';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PeopleIcon from '@material-ui/icons/People';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BusinessIcon from '@material-ui/icons/Business';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { PERMISSIONS } from './constants';
import DateRangeIcon from '@material-ui/icons/DateRange';

interface IRouteApp extends IRoute {
  permission?: PERMISSIONS;
  routes?: undefined | IRouteApp[];
}

const routes: IRouteApp[] = [
  {
    path: '/',
    title: 'dashboard',
    icon: DashboardIcon,
    component: lazy(() => import(/* webpackChunkName: 'Dashboard' */ 'pages/Dashboard')),
  },
  {
    path: '/companies',
    title: 'companies',
    icon: BusinessIcon,
    routes: [
      {
        path: '/index',
        title: 'companies',
        component: lazy(() => import(/* webpackChunkName: 'Companies' */ 'pages/Companies')),
      },
    ],
  },
  {
    path: '/members',
    title: 'members',
    permission: PERMISSIONS.EDIT_CRM_USERS,
    icon: PeopleIcon,
    routes: [
      {
        path: '/index',
        title: 'members',
        component: lazy(() => import(/* webpackChunkName: 'Members' */ 'pages/Members')),
      },
      {
        path: '/pending',
        title: 'members-pending',
        component: lazy(
          () => import(/* webpackChunkName: 'MembersPending' */ 'pages/MembersPending'),
        ),
      },
      {
        path: '/chat-conversations',
        title: 'chat-conversations',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'MembersChatConversations' */ 'pages/MembersChatConversations'
            ),
        ),
      },
    ],
  },
  {
    path: '/customers',
    title: 'customers',
    icon: AttachMoneyIcon,
    routes: [
      {
        path: '/index',
        title: 'customers-list',
        component: lazy(
          () => import(/* webpackChunkName: 'CustomersList' */ 'pages/CustomersList'),
        ),
      },
      {
        path: '/events',
        title: 'customer-networking-events-list',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CustomerNetworkingEventList' */ 'pages/CustomerNetworkingEventList'
            ),
        ),
      },
    ],
  },
  {
    path: '/networking-events',
    title: 'networking-events',
    icon: DateRangeIcon,
    routes: [
      {
        path: '/index',
        title: 'networking-events-list',
        component: lazy(
          () => import(/* webpackChunkName: 'NetworkingEvents' */ 'pages/NetworkingEvents'),
        ),
      },
      {
        path: '/customers',
        title: 'networking-events-customers',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ 'pages/ZTemp')),
      },
      {
        path: '/testimonials',
        title: 'networking-events-testimonials',
        component: lazy(() => import(/* webpackChunkName: 'Testimonials' */ 'pages/Testimonials')),
      },
      {
        path: '/landing',
        title: 'networking-events-landing-page',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/network-events-landing'
            ),
        ),
      },
    ],
  },
  {
    path: '/campaigns',
    title: 'campaigns',
    icon: TrackChangesIcon,
    routes: [
      {
        path: '/index',
        title: 'campaigns',
        component: lazy(() => import(/* webpackChunkName: 'Campaigns' */ 'pages/Campaigns')),
      },
      {
        path: '/email-templates',
        title: 'email-templates',
        component: lazy(
          () => import(/* webpackChunkName: 'EmailTemplates' */ 'pages/EmailTemplates'),
        ),
      },
      {
        path: '/email-subjects',
        title: 'email-subjects',
        component: lazy(
          () => import(/* webpackChunkName: 'EmailSubjects' */ 'pages/EmailSubjects'),
        ),
      },
      {
        path: '/email-service-providers',
        title: 'email-service-providers',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'EmailServiceProviders' */ 'pages/EmailServiceProviders'),
        ),
      },
    ],
  },
  {
    path: '/app-settings',
    title: 'app-settings',
    icon: SettingsApplicationsIcon,
    permission: PERMISSIONS.EDIT_WEBSITES,
    routes: [
      {
        path: '/business-activities',
        title: 'business-activities',
        component: lazy(
          () => import(/* webpackChunkName: 'BusinessActivities' */ 'pages/BusinessActivities'),
        ),
      },
      {
        path: '/business-field-of-interests',
        title: 'business-field-of-interests',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'BusinessFieldOfInterests' */ 'pages/BusinessFieldOfInterests'
            ),
        ),
      },
      {
        path: '/ingredient-types',
        title: 'ingredient-types',
        component: lazy(
          () => import(/* webpackChunkName: 'IngredientTypes' */ 'pages/IngredientTypes'),
        ),
      },
      {
        path: '/product-types',
        title: 'product-types',
        component: lazy(() => import(/* webpackChunkName: 'ProductTypes' */ 'pages/ProductTypes')),
      },
      {
        path: '/countries',
        title: 'countries',
        component: lazy(() => import(/* webpackChunkName: 'Countries' */ 'pages/Countries')),
      },
      {
        path: '/stages',
        title: 'development-stages',
        component: lazy(
          () => import(/* webpackChunkName: 'DevelopmentStages' */ 'pages/DevelopmentStages'),
        ),
      },
      {
        path: '/field-of-occupations',
        title: 'field-of-occupations',
        component: lazy(
          () => import(/* webpackChunkName: 'FieldOfOccupations' */ 'pages/FieldOfOccupations'),
        ),
      },
      {
        path: '/medical-fields',
        title: 'medical-fields',
        component: lazy(
          () => import(/* webpackChunkName: 'MedicalFields' */ 'pages/MedicalFields'),
        ),
      },
      {
        path: '/network-items',
        title: 'network-items',
        component: lazy(() => import(/* webpackChunkName: 'NetworkItems' */ 'pages/NetworkItems')),
      },
      {
        path: '/professional-fields',
        title: 'professional-fields-of-interests',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'ProfessionalFieldsOfInterests' */ 'pages/ProfessionalFieldsOfInterests'
            ),
        ),
      },
      {
        path: '/regions',
        title: 'regions',
        component: lazy(() => import(/* webpackChunkName: 'Regions' */ 'pages/Regions')),
      },
      {
        path: '/title-of-names',
        title: 'title-of-names',
        component: lazy(() => import(/* webpackChunkName: 'TitleOfNames' */ 'pages/TitleOfNames')),
      },
      {
        path: '/unauthorized-email-domains',
        title: 'unauthorized-email-domains',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'UnauthorizedEmailDomains' */ 'pages/UnauthorizedEmailDomains'
            ),
        ),
      },
      {
        path: '/user-positions',
        title: 'user-positions',
        component: lazy(
          () => import(/* webpackChunkName: 'UserPositions' */ 'pages/UserPositions'),
        ),
      },
      {
        path: '/health-care-categories',
        title: 'health-care-categories',
        component: lazy(
          () => import(/* webpackChunkName: 'HealthCareCategories' */ 'pages/HealthCareCategories'),
        ),
      },
      {
        path: '/health-care-sub-categories',
        title: 'health-care-sub-categories',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'HealthCareSubCategories' */ 'pages/HealthCareSubCategories'
            ),
        ),
      }
    ],
  },
  {
    path: '/content',
    title: 'content',
    icon: AnnouncementIcon,
    routes: [
      {
        path: '/dashboard',
        title: 'dashboard',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/dashboard'),
        ),
      },
      {
        path: '/home',
        title: 'home',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/home'),
        ),
      },
      {
        path: '/our-members',
        title: 'our-members',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/our-members'),
        ),
      },
      {
        path: '/terms',
        title: 'terms',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/terms'),
        ),
      },
      {
        path: '/for-companies',
        title: 'for-companies',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/companies'),
        ),
      },
      {
        path: '/privacy',
        title: 'privacy',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/privacy'),
        ),
      },
      {
        path: '/for-individuals',
        title: 'for-individuals',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/individuals'),
        ),
      },
      {
        path: '/faq',
        title: 'faq',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/fqa'),
        ),
      },
      {
        path: '/what-offer',
        title: 'what-do-we-offer',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/what-do-we-offer'),
        ),
      },
      {
        path: '/who-we-are',
        title: 'who-we-are',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/who-we-are'),
        ),
      },
      {
        path: '/who-is-in',
        title: 'who-is-in',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/who-is-in'),
        ),
      },

    ],
  },
  {
    path: '/settings',
    title: 'settings',
    icon: SettingsIcon,
    permission: PERMISSIONS.EDIT_SETTINGS,
    routes: [
      {
        path: '/users',
        title: 'users-crm',
        permission: PERMISSIONS.EDIT_CRM_USERS,
        component: lazy(() => import(/* webpackChunkName: 'Users' */ 'pages/Users')),
      },
      {
        path: '/permissions',
        title: 'permissions',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(() => import(/* webpackChunkName: 'Permissions' */ 'pages/Permissions')),
      },
      {
        path: '/site-parameters',
        title: 'site-parameters',
        component: lazy(
          () => import(/* webpackChunkName: 'SiteParameters' */ 'pages/SiteParameters'),
        ),
      },
      {
        path: '/external-channels',
        title: 'external-channels',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(
          () => import(/* webpackChunkName: 'ExternalChannels' */ 'pages/ExternalChannels'),
        ),
      },
      {
        path: '/labels-crm',
        title: 'labels-crm',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ 'pages/Labels/crm')),
      },
      {
        path: '/labels-app',
        title: 'labels-app',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ 'pages/Labels/app')),
      },
      {
        path: '/labels-web',
        title: 'labels-website',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ 'pages/Labels/website')),
      },
      {
        path: '/log',
        title: 'log',
        permission: PERMISSIONS.VIEW_LOG,
        component: lazy(() => import(/* webpackChunkName: 'Log' */ 'pages/Log')),
      },
    ],
  },
  {
    path: '/profile',
    title: 'profile',
    inMenu: false,
    component: lazy(() => import(/* webpackChunkName: 'Profile' */ 'pages/Profile')),
  },
  {
    path: '/video',
    title: 'video',
    icon: SettingsIcon,
    inMenu: false,
    private: false,
    inLayout: false,
    routes: [
      {
        path: '/create-room',
        title: 'create-room',
        inMenu: false,
        private: false,
        inLayout: false,
        component: lazy(
          () => import(/* webpackChunkName: 'VideoRoomCreate' */ 'pages/VideoRoomCreate'),
        ),
      },
      {
        path: '/room',
        title: 'room',
        inMenu: false,
        private: false,
        inLayout: false,
        component: lazy(() => import(/* webpackChunkName: 'VideoRoom' */ 'pages/VideoRoom')),
      },
    ],
  },
  {
    path: '/login',
    title: 'login',
    inMenu: false,
    private: false,
    inLayout: false,
    component: lazy(() => import(/* webpackChunkName: 'Login' */ 'pages/Login')),
  },
];

export default routes;
