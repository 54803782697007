import React, {FC, memo, useCallback, useMemo, useState} from "react";
import {IDataGridColumn} from "../../model";
import {checkEs6AndRun, es6Run, regExpFieldAS} from "../../../helpers";
import EditorString from "./editors/editor-string";
import EditorCheckbox from "./editors/editor-checkbox";
import EditorNumber from "./editors/editor-number";
import EditorSelect from "./editors/editor-select";
import {format, isValid} from "date-fns";
import ImageTooltip from "../../../image-tooltip";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

export const templateParser = (column: IDataGridColumn, data: any) => {
  const result: any = {type: 'string', template: ''};
  if (typeof column.template === 'string') {
    result.type = column.template;
    const data_ = es6Run(data, column.field);
    switch (column.template) {
      case 'string':
      case 'number':
        result.template = data_;
        break;
      case 'email':
        result.template = (<a href={`mailto:${data_}`}>{data_}</a>);
        break;
      case 'link':
        result.template = (<a href={data_} target="_blank" rel="noopener noreferrer">{data_}</a>);
        break;
      case 'html':
        result.template = <div className="html-wrapper" dangerouslySetInnerHTML={{__html: data_ || ''}}/>;
        break;
      case 'date':
        const date_ = new Date(data_);
        result.template = isValid(date_) ? format(date_, 'dd MMMM yyyy') : '';
        break;
      case 'boolean':
        result.template = (data_) ? <CheckCircleOutlineIcon color="secondary" className="template-boolean"/> :
          <RadioButtonUncheckedIcon color="secondary" className="template-boolean"/>;
        break;
      case 'image':
        result.template = (<ImageTooltip src={data_} config={{placement: 'right'}}/>);
        break;
      default:
        result.template = checkEs6AndRun(column.template, data);
        break;
    }
  } else if (typeof column.template === 'object' && column.template.type) {
    const template_ = column.template;
    result.type = template_.type;
    switch (template_.type) {
      case 'date':
        const date_ = new Date(data[column.field]);
        result.template = isValid(date_) ? format(date_, template_.format) : '';
        break;
      case 'image':
        let src_ = template_.src;
        let title_ = template_.title;
        // @ts-ignore
        if (regExpFieldAS.test(src_)) src_ = src_.match(regExpFieldAS)[0].slice(4);
        // @ts-ignore
        if (regExpFieldAS.test(title_)) title_ = title_.match(regExpFieldAS)[0].slice(4);
        result.template = (<ImageTooltip
          config={{placement: 'right'}}
          src={es6Run(data, src_)}
          title={es6Run(data, title_)}
        />);
        break;
    }
  } else if (typeof column.template === 'function') {
    result.template = column.template(data);
    result.type = 'function';
  }
  return result;
};
export const editorParser = (column: IDataGridColumn, data: any, onSubmit: (value: any) => void, onClose: () => void) => {
  if (column.editor) {
    // @ts-ignore
    switch (column.editor.type) {
      case "string":
        // @ts-ignore
        return (<EditorString editor={column.editor} data={data} onSubmit={onSubmit} onClose={onClose}/>);
      case "number":
        // @ts-ignore
        return (<EditorNumber editor={column.editor} data={data} onSubmit={onSubmit} onClose={onClose}/>);
      case "boolean":
        // @ts-ignore
        return (<EditorCheckbox data={data} onSubmit={onSubmit} onClose={onClose}/>);
      case "select":
        // @ts-ignore
        return (<EditorSelect editor={column.editor} data={data} onSubmit={onSubmit} onClose={onClose}/>);
    }
  }
  return null;
};

const Column: FC<{
  column: IDataGridColumn;
  data: any;
  onTasks: (props: any) => void;
  preview?: boolean;
}> = ({column, data, onTasks, preview}) => {
  // state
  const [edit, setEdit] = useState(false);
  // editor handlers
  const onSubmit = useCallback((value) => {
    // @ts-ignore
    const {field} = column.editor;
    if (data[field] !== value.data) {
      onTasks([['pathData', {row: {...data}, value: {[field]: value.data}}]]);
    }
    setEdit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const onClose = useCallback(() => setEdit(false), []);
  // components
  const col = useMemo(() => templateParser(column, data), [column, data]);
  // @ts-ignore
  const editor = useMemo(() => preview ? null : editorParser(column, data[column.editor?.field], onSubmit, onClose),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [column, data]);
  // state handlers
  const onTdClick = useCallback(() => setEdit(Boolean(editor)), [editor, setEdit]);
  return (
    <td
      onClick={onTdClick}
      className={`${column.tdClasses} ${col.type} ${Boolean(editor) ? 'is-edit-allow' : ''} ${edit ? 'is-edit' : ''}`}
    >
      {col.template}
      {edit && editor}
    </td>
  )
};

export default memo(Column);
