// eslint-disable-next-line
import React, {
  cloneElement,
  createElement,
  forwardRef,
  isValidElement,
  memo,
  Ref,
  useCallback,
  useImperativeHandle,
  useState
} from "react";
import {apiStatic, checkEs6AndRun, requestError} from "../helpers";
import {notifyRequestResult} from "../../store/modules/notify";
import {debounce} from "lodash";
import {useDispatch} from "react-redux";

export const DataGridWrapper = memo(forwardRef((
  {
    api,
    render,
    requestParams = {},
    // eslint-disable-next-line
    searchTemplate = 'title.contains("${data}")',
    pageSize = 10,
    dataParse = null
  }: {
    api: string;
    render?: any;
    requestParams?: {};
    searchTemplate?: string;
    pageSize?: number;
    dataParse?: null | Function,
  }, ref: Ref<any>) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [sortable, setSortable] = useState(null);
  const [order, setOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [init, setInit] = useState(false);
  // methods
  const getData = useCallback(({page, sortable, order, search}) => {
    setLoading(true);
    setPage(page);
    setSortable(sortable);
    setOrder(order);
    setSearch(search);
    const params: any = {
      ...requestParams,
      Take: pageSize,
      Skip: page * pageSize,
      Count: true
    };
    if (sortable) params.OrderBy = `${sortable} ${order}`;
    if (search) params.Filter = `${params.Filter ? `${params.Filter} && ` : ''}${checkEs6AndRun(searchTemplate, search)}`;
    apiStatic
      .get(api, {params})
      .then(response => {
        const data_ = dataParse ? dataParse(response.data) : response.data;
        setData(data_.value);
        setTotal(Math.max(0, Math.ceil(data_.count / pageSize) - 1));
        setLoading(false);
        setInit(true);
      })
      .catch(error => {
        setLoading(false);
        dispatch(notifyRequestResult(requestError(error), 'error'));
        console.error(error);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, setLoading, setPage, setTotal, setSortable, setOrder, setSearch, setInit]);
  const onPage = useCallback((page) => {
    getData({page, sortable, order, search});
  }, [sortable, order, search, getData]);
  const onSortable = useCallback((value) => {
    if (sortable !== value) {
      // eslint-disable-next-line
      getData({page: 0, sortable: value, order: 'asc', search});
    } else {
      if (order === 'desc') {
        getData({page: 0, sortable: null, order: null, search});
      } else {
        getData({page: 0, sortable: value, order: 'desc', search});
      }
    }
  }, [sortable, order, search, getData]);
  const onSearchDebounce = useCallback(debounce((value: string) => {
    getData({page: 0, sortable, order, search: value});
  }, 500), [sortable, order, getData]);
  const onSearch = useCallback((value: string) => {
    setSearch(value);
    onSearchDebounce(value);
  }, [setSearch, onSearchDebounce]);
  const task = useCallback((name: string, payload?: any) => {
    switch (name) {
      case 'data':
        getData(payload ? payload : {page: 0, sortable: null, order: null, search: ''});
        break;
      case 'page':
        onPage(payload);
        break;
      case 'sortable':
        onSortable(payload);
        break;
      case 'search':
        onSearch(payload);
        break;
    }
  }, [getData, onPage, onSortable, onSearch]);
  // public
  useImperativeHandle(ref, () => ({task: task}));
  const props = {
    data,
    loading,
    page,
    total,
    sortable,
    order,
    search,
    task,
    getData,
    onPage,
    onSortable,
    onSearch,
    init
  };
  return (render)
    ? isValidElement(render)
      ? cloneElement(render, props)
      : createElement(render as string, props)
    : null;
}));
export default DataGridWrapper;
